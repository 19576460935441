import * as React from 'react';
import { navigate } from 'gatsby'
import api from '../util/api';
import {
  Box, CircularProgress, Typography
} from '@mui/material';
import {
  createTheme,
  ThemeProvider
} from '@mui/material/styles';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import './index.css';

const MySwal = withReactContent(Swal)
export default function ProcessLine(props) {
  const handleOnClose = () => {
    console.log('sadasdsadas')
    navigate('/login')
  }

  let theme = createTheme({
    typography: {
      'fontFamily': `'Kanit', sans-serif`,
    },
  });

  React.useEffect(async () => {
    const params = new URLSearchParams(props.location.search);
    const code = params.get("code");
    const state = params.get("state");

    if (state === 'isee_line_connect') {
      let line_connect = await api.authLineToEnable(code, state)
      if (line_connect.message === "Line Connect Profile Success") {
        navigate('/editprofile')
      } else if (line_connect.message === "ยังไม่ได้ลงทะเบียนในระบบ") {
        api.onLogout()
      } else {
        navigate('/404')
      }
    } else {
      let profile = await api.authLineGetProfile(code, state)
      if (profile.message === "Login Success") {
        navigate('/')
      } else if (profile.message === "ยังไม่ได้ลงทะเบียนในระบบ") {
        navigate('/register', { state: { profile } })
      } else if (profile.message === "disabled line connect") {
        Swal.fire('', 'คุณปิดฟังก์ชั่นการเข้าใช้งานระบบผ่าน LINE กรุณาเข้าสู่ระบบและเปิดการใช้งานได้ที่ แก้ไขข้อมูลส่วนตัว' + ' นี้แล้ว', 'error')
      } else {
        navigate('/404')
      }
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Box className='justify-center w-[100vw] h-[100vh]' sx={{ display: 'flex' }}>
        <div className='my-auto text-center'>
          <CircularProgress size={180} className='my-8' />
          <Typography variant='h4'>
            กำลังประมวลผล ....
          </Typography>
        </div>
      </Box>
    </ThemeProvider>
  );
}